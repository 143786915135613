import React from "react"
import { graphql } from "gatsby"
import PropTypes from "prop-types"
import SEO from "../components/seo/seo"
import ArticleListing from "../components/ArticleListing/"
import "./index.sass"
import PageTransition from "../components/PageTransition"

const RecommendedPage = (props) => {

	const { data } = props
	const posts = data.allMarkdownRemark.edges

	return (

		<PageTransition {...props}>

			<SEO title="Recommended" keywords={[]} />

			<div className="homepage">

				<h1 id="main-title">Recomended</h1>

				<ArticleListing posts={posts} />

			</div>

		</PageTransition>

	)

}

RecommendedPage.propTypes = {

	data: PropTypes.object.isRequired,

}

export default RecommendedPage

export const pageQuery = graphql`

	query {
		allMarkdownRemark(

			filter: {
				fields: {
					category: {eq: "Recommended"}
				}
			}

			sort: {
				order: DESC, fields: [frontmatter___publicationDate]
			}

		) {
		edges {
				...indexListing
			}
		}
	}

`