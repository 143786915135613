import React from "react"
import SEO from "../components/seo/seo"
import "./contentpage.sass"
import SoupHero from "../components/ImageComponents/SoupHero"
import SoupShare from "../components/ImageComponents/SoupShare"
import SoupLive from "../components/ImageComponents/SoupLive"
import SoupPodcast from "../components/ImageComponents/SoupPodcast"
import SoupStrong from "../components/ImageComponents/SoupStrong"
import SoupAdFree from "../components/ImageComponents/SoupAdFree"
import PageTransition from "../components/PageTransition";

const AboutPage = (props) => {

	return (

		<PageTransition {...props}>

			<SEO
				title="About Us"
				description='The world is full of awesome and amazing STUFF, and thats why Deviant Robot is here.'
				pathname="/about"
			/>

			<div className="page-about">

				<h1 id="main-title">Deviant Robot</h1>

				<p className="mission-statement">Meandering adventures into<br />interesting stuff.</p>

				<hr />

				<div className="page">

					<p>The world is full of awesome and amazing <strong>STUFF</strong>, and Deviant Robot is here to serve that <strong>STUFF</strong> up to you with a light side salad of sass and charm.</p>

				</div>

				<section>

					<div className="image-container">

						<SoupHero />

					</div>

					<div className="details">

						<h2>Only the best ingredients</h2>
						<p>Art, history, culture, video games, literature, movies, comics, philosophy and more - it's all fair game. If it’s made of deliciously tasty and interesting <strong>STUFF</strong> then we will happily fling it into the mighty soup that is Deviant Robot. We hope you will drink heartily of this soup and ask for seconds. We hope you will enjoy its surprising chunks and mysterious aroma.</p>

					</div>

				</section>

				<section>

					<div className="image-container">

						<SoupLive />

					</div>

					<div className="details">

						<h2>Live events</h2>
						<p>We will unite soup lovers everywhere at our live events, to partake of the great soup together and in doing so forge the mighty bonds of friendship.</p>

						{/* <Link to="/events" className="button primary">More about our events</Link> */}
						<p><strong>More information about the live events coming soon.</strong></p>

					</div>

				</section>

				<section>

					<div className="image-container">

						<SoupPodcast />

					</div>

					<div className="details">

						<h2>Ear Soup on the go</h2>
						<p>Enjoy Deviant Robot soup on the go in the form of our pre-packaged podcasts; lively satisfying soup poured into your brain through your ears while you journey to your place of work.</p>
						{/* <Link to="/podcasts" className="button primary">More about our podcasts</Link> */}
						<p><strong>More information about the podcasts coming soon.</strong></p>

					</div>

				</section>

				<section>

					<div className="image-container">

						<SoupStrong />

					</div>

					<div className="details">

						<h2>Build your immune system</h2>
						<p>We want to fill the Deviant Robot soup with so much good stuff that it will help you build up your strength for battle with the many challenges put forth by that harsh mistress known as life.</p>

					</div>

				</section>

				<section>

					<div className="image-container">

						<SoupAdFree />

					</div>

					<div className="details">

						<h2>No artificial colouring</h2>
						<p>Unlike other soups, our soup is made only from real ingredients and is 100% free form the unhealthy additives of sponsored content and advertising or the artificial flavouring of political, religious or social agendas.</p>

					</div>

				</section>

				<section>

					<div className="image-container">

						<SoupShare />

					</div>

					<div className="details">

						<h2>Good to share</h2>
						<p>Why not serve up this delicious Deviant Robot soup to your friends and family? Perhaps take some to work and share it around the office. Tell people you made it yourself if you like, but defiantly share it.  Most of the things in it aren’t ours anyway, it's STUFF we found and thought looked tasty. We really aren’t at all sure where some of it came from actually.<br />Maybe space! </p>

					</div>

				</section>

				<div className="page">

					<h2>In Conclusion</h2>
					<p>The Deviant Robot Soup, we think you will love it. It's an honest, well balanced nutritious meal fresh made lovely and sexually charismatic people. People who may not have washed hands before making it, but certainly people who are not afraid to ruin their search engine optimisation by needlessly implying their website is, in fact, a delicious soup.</p>
					<p>Bon Appétit Deviants, Bon Appétit</p>

				</div>

			</div>

		</PageTransition >

	)

}
export default AboutPage
