import React, { createContext, useContext, useReducer } from 'react';

export const StateContext = createContext();

export const StateProvider = ({ reducer, initialState, children }) => {

	// Get state from storage and if found override
	// the inital state with the local storage values.

	const localStorageState = (typeof window !== `undefined`) ? JSON.parse(window.localStorage.getItem("applicatinState")) : null

	// Regardless of the previous state some options should
	// be overriden on first load.

	const sensibleOverides = {

		options: {
			open: false // Make sure that the options menu is closed
		},
		page: {
			title: null // Don't remember the previously set page title
		},
		nsfw: {
			show: false // Always hide nsfw on a new session
		}

	}

	initialState = localStorageState == null ? initialState : {
		...initialState,
		...localStorageState,
		...sensibleOverides
	}

	return (

		<StateContext.Provider value={useReducer(reducer, initialState)}>

			{children}

		</StateContext.Provider>

	)

};

export const useStateValue = () => useContext(StateContext);