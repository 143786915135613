import React from 'react'
import Img from "gatsby-image"
import { useStateValue } from '../State';
import { Link } from "gatsby"
import "./ArticleListingItem.sass"

const ArticleListingItem = (props) => {

	const [state, dispatch] = useStateValue()

	const {
		tileImage,
		featuredImage,
		hasVideo,
		category,
		collection,
		title,
		abstract,
		timeToRead,
		isNsfw,
		slug,
		featured
	} = props

	const tileImageActual = tileImage ? tileImage : featuredImage

	const toggleShowNSFW = () => {

		if (state.nsfw.show) {

			dispatch({ type: 'DISABLE_NSFW' })

		} else {

			dispatch({ type: 'ENABLE_NSFW' })

		}

	}

	if (isNsfw && !state.nsfw.show) {

		return (

			<div className="article-listing-item nsfw">

				<div className="nsfw-message">

					<h4>NSFW</h4>
					<p>This post is marked as not safe for work</p>
					<div onClick={toggleShowNSFW} className="show">Show all NSFW content</div>

				</div>

			</div>

		)

	} else {

		return (

			<Link className="article-listing-item" to={slug}  >

				{tileImageActual && <Img fadeIn={false} alt={title} title={title} placeholderClassName="image-placeholder" className="featured-image" sizes={tileImageActual.childImageSharp.sizes} />}

				{(hasVideo && tileImageActual)
					&& <div className="has-video">Video</div>}

				<div className="contnet">

					<div className="category">{category}</div>

					{
						(collection && (collection !== category))
						&& <div className="collection">{collection}</div>
					}

					{featured ?
						<h2>{title}</h2>
						:
						<h3>{title}</h3>
					}

					<p>{abstract}</p>

					<div className="meta">

						<span className="time-to-read">Reading time <strong>{timeToRead}min</strong></span>

					</div>

				</div>

			</Link>

		)
	}

}

export default ArticleListingItem