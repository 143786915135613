const reducer = (state, action) => {

	switch (action.type) {

		case 'SET_PAGE_TITLE':

			return {
				...state,
				page: {
					...state.options,
					title: action.title
				}
			};

		case 'RESET_PAGE_TITLE':

			return {
				...state,
				page: {
					...state.options,
					title: null
				}
			};

		case 'ENABLE_OPTIONS_OPEN':

			return {
				...state,
				options: {
					...state.options,
					open: true
				}
			};

		case 'DISABLE_OPTIONS_OPEN':

			return {
				...state,
				options: {
					...state.options,
					open: false
				}
			};

		case 'ENABLE_MENU_OPEN':

			return {
				...state,
				menu: {
					...state.menu,
					open: true
				}
			};

		case 'DISABLE_MENU_OPEN':

			return {
				...state,
				menu: {
					...state.menu,
					open: false
				}
			};

		case 'DISABLE_DARK_THEME':

			return {
				...state,
				theme: {
					...state.theme,
					dark: false
				}
			};

		case 'ENABLE_DARK_THEME':

			return {
				...state,
				theme: {
					...state.theme,
					dark: true

				}
			};

		case 'ENABLE_NSFW':

			return {
				...state,
				nsfw: {
					...state.nsfw,
					show: true
				}
			};

		case 'DISABLE_NSFW':

			return {
				...state,
				nsfw: {
					...state.nsfw,
					show: false
				}
			};

		case 'ENABLE_COOKIE_consent':

			return {
				...state,
				cookies: {
					...state.cookies,
					consent: true
				}
			};

		case 'DISABLE_COOKIE_consent':

			return {
				...state,
				cookies: {
					...state.cookies,
					consent: false
				}
			};

		case 'PODCAST_SET_PLAYING':

			return {
				...state,
				podcast: {
					...state.podcast,
					playing: action.data.playing
				}
			};

		case 'PODCAST_SET':

			return {
				...state,
				podcast: {
					selected: true,
					playing: true,
					mediaURL: action.data.mediaURL,
					mediaTitle: action.data.mediaTitle,
					mediaArt: action.data.mediaArt,
					playPosition: 0,
				}
			};

		default:

			return state;

	}

}

export default reducer