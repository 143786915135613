/**
 * SEO component that queries for data with
 *  Gatsby's useStaticQuery React hook
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import React from "react"
import PropTypes from "prop-types"
import Helmet from "react-helmet"
import { useStaticQuery, graphql } from "gatsby"

function SEO({
	description, lang, meta, keywords, title, image, pathname, titleTemplate,
}) {

	const { site, placeholderImage } = useStaticQuery(
		graphql`
			query {
				site {
					siteMetadata {
						title
						description
						author
						baseKeywords
						siteUrl
					}
				}
				placeholderImage: file(relativePath: { eq: "default-share.jpg" }) {
					childImageSharp {
						sizes(maxWidth: 500) {
							src
						}
					}
				}
			}`,
	)

	const siteUrl = site.siteMetadata.siteUrl
	const url = siteUrl + pathname || '/'
	const metaDescription = description || site.siteMetadata.description
	const allKeywords = keywords ? keywords.concat(site.siteMetadata.baseKeywords).join(`, `) : site.siteMetadata.baseKeywords

	return (
		<Helmet
			htmlAttributes={{
				lang,
			}}
			title={title}
			titleTemplate={titleTemplate || `%s | ${site.siteMetadata.title}`}
			link={[
				{
					type: "text/plain",
					rel: "author",
					href: siteUrl + "/humans.txt",
				}
			]}
			meta={[
				{
					name: `description`,
					content: metaDescription,
				},
				{
					property: `og:title`,
					content: title,
				},
				{
					property: `og:url`,
					content: url,
				},
				{
					property: `og:description`,
					content: metaDescription,
				},
				{
					property: `og:type`,
					content: `website`,
				},
				{
					name: `twitter:card`,
					content: `summary`,
				},
				{
					name: `twitter:creator`,
					content: site.siteMetadata.author,
				},
				{
					name: `twitter:title`,
					content: title,
				},
				{
					name: `twitter:description`,
					content: metaDescription,
				},
			]
				.concat(

					image ?
						{
							property: `og:image`,
							content: siteUrl + image,
						}
						: {
							property: `og:image`,
							content: siteUrl + placeholderImage.childImageSharp.sizes.src,
						},

				)
				.concat(
					allKeywords.length > 0
						? {
							name: `keywords`,
							content: allKeywords,
						}
						: [],
				)
				.concat(meta)}
		/>
	)

}

SEO.defaultProps = {
	lang: `en`,
	meta: [],
	keywords: [],
	description: ``,
}

SEO.propTypes = {
	description: PropTypes.string,
	lang: PropTypes.string,
	meta: PropTypes.arrayOf(PropTypes.object),
	keywords: PropTypes.arrayOf(PropTypes.string),
	title: PropTypes.string.isRequired,
}

export default SEO
