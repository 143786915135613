import React, { useEffect, useState } from "react"
import PropTypes from "prop-types"
import { StateProvider, useStateValue } from '../State';
import initialState from '../State/initialState';
import reducer from '../State/reducer';
import { Link, useStaticQuery, graphql } from "gatsby"
import ReactGA from 'react-ga';
import "./layout.sass"
import MenuIcon from "./menu.inline.svg"
import SunIcon from "./sun.inline.svg"
import MoonIcon from "./moon.inline.svg"
import NsfwIcon from "./nsfw.inline.svg"
import SfwIcon from "./sfw.inline.svg"
import AngleIcon from "./angle.inline.svg"
import OptionsIcon from "./options.inline.svg"
import DeviantRobotLogo from "./deviantrobot.inline.svg"
import Headroom from 'react-headroom'
import Konami from 'react-konami-code'
import PodcastPlayer from "../PodcastPlayer";
import InstagramIcon from './instagram-icon.inline.svg'
import TwitterIcon from './twitter-icon.inline.svg'
import YoutubeIcon from './youtube-icon.inline.svg'
import FacebookIcon from './facebook-icon.inline.svg'
import MeetupIcon from './meetup-icon.inline.svg'
import TwitchIcon from './twitch-icon.inline.svg'

const Layout = (props) => {

	const { children } = props
	const [state, dispatch] = useStateValue();
	const [googleAnalyticsHasInitialised, setGoogleAnalyticsHasInitialised] = useState(false);
	const [googleAnalyticsCurrentLocation, setGoogleAnalyticsCurrentLocation] = useState();
	const [activePrimaryNavigationMenu, setActivePrimaryNavigationMenu] = useState('main');

	const { site } = useStaticQuery(

		graphql`
			query {
				site {
					buildTime(formatString: "YYYY-MM-DD-HH-MM-SS")
					siteMetadata {
						googleAnalyticsTrackingID
					}
				}
			}`,

	)

	useEffect(

		() => {

			if (typeof window !== `undefined`) {

				// SECTION: Conditioanl status body classes

				const conditionalBodyClass = (condition, className) => {

					// Document check to ensure we are not server side
					// rendering during a Gatsby build.

					if (condition) {

						document.body.classList.add(className)

					} else {

						document.body.classList.remove(className)

					}

				}

				const conditionalHTMLClass = (condition, className) => {

					// Document check to ensure we are not server side
					// rendering during a Gatsby build.

					if (condition) {

						document.documentElement.classList.add(className)

					} else {

						document.documentElement.classList.remove(className)

					}

				}

				conditionalHTMLClass(state.options.open, "scrollLock")

				conditionalBodyClass(state.cookies.consent, "stateCookieconsent")
				conditionalBodyClass(state.menu.open, "stateMenuOpen")
				conditionalBodyClass(!state.theme.dark, "stateUiThemeLight")
				conditionalBodyClass(state.options.open, "stateOptionsOpen")

				// !SECTION

				// SECTION: Google Analytics

				// NOTE: Google Anlytics with Opt-In user consent based on a local
				// storage state preference. We need to check for window so that
				// we dont cause errors with the Gatsby static build.

				if (state.cookies.consent) {

					const trackingURL = window.location.pathname + window.location.search + window.location.hash

					// If we have consent and have not yet initialised the google
					// analytics we should do so.

					if (!googleAnalyticsHasInitialised) {

						// Google Analytics Tracking ID is stored in gatsby-config.js

						ReactGA.initialize(site.siteMetadata.googleAnalyticsTrackingID);

						// Setting the 'anonymizeIp' property to anonymize the IP address of
						// the hit sent to Google Analytics.

						ReactGA.set({ anonymizeIp: true });

						// Recording Google Anlytics has been set for this session in
						// component state.

						// This is not stored in Local Storage or Global State as it is
						// session specific. The layout component wraps the application
						// routes so component state will persisit for the session.

						setGoogleAnalyticsHasInitialised(true)

					}

					// If the current location hasn't been tracked, we should
					// track the page view.

					if (googleAnalyticsCurrentLocation !== trackingURL) {

						ReactGA.pageview(trackingURL);
						setGoogleAnalyticsCurrentLocation(trackingURL)

					}

				}

				// !SECTION

				// SECTION: Local Storage of state

				// NOTE: Provided that we have consent listen for changes in the state,
				// when changed it update the localStorage value of state

				if (state.cookies.consent) {

					window.localStorage.setItem("applicatinState", JSON.stringify(state));

				} else {

					window.localStorage.removeItem("applicatinState");

				}

				// !SECTION

			}

		},
		[state]

	);

	let KonamiMessage = "Your browser is too weak."
	if (typeof window !== `undefined`) { KonamiMessage = window.atob("WW91IGhhdmUgdGFrZW4gdGhlIGZpcnN0IHN0ZXAuIENvbWUgYmFjayBsYXRlciBmb3IgdGhlIG5leHQu") }

	// SECTION: User preference change event handelers

	const toggleMenu = () => {

		if (state.menu.open) {

			dispatch({ type: 'DISABLE_MENU_OPEN' })

		} else {

			dispatch({ type: 'ENABLE_MENU_OPEN' })

		}

	}

	const closeOptions = () => {

		if (state.options.open) {

			dispatch({ type: 'DISABLE_OPTIONS_OPEN' })

		}

	}

	const toggleOptions = () => {

		if (state.options.open) {

			dispatch({ type: 'DISABLE_OPTIONS_OPEN' })

		} else {

			dispatch({ type: 'ENABLE_OPTIONS_OPEN' })

		}

	}

	const toggleShowNSFW = () => {

		if (state.nsfw.show) {

			dispatch({ type: 'DISABLE_NSFW' })

		} else {

			dispatch({ type: 'ENABLE_NSFW' })

		}

	}

	const toggleDarkTheme = () => {

		if (state.theme.dark) {

			dispatch({ type: 'DISABLE_DARK_THEME' })

		} else {

			dispatch({ type: 'ENABLE_DARK_THEME' })

		}

	}

	const cookieconsentToogle = () => {

		if (state.cookies.consent) {

			dispatch({ type: 'DISABLE_COOKIE_consent' })

		} else {

			dispatch({ type: 'ENABLE_COOKIE_consent' })

		}

	}

	// !SECTION

	return (

		<>

			<a className="skip-to-content visuallyhidden focusable" href="#content">Skip to content</a>

			<div id="top" className="layout">

				<Headroom disableInlineStyles>

					<header className="site">

						<MenuIcon onClick={toggleMenu} className="menu-icon" />

						<div className="site-title">

							<Link onClick={() => { setActivePrimaryNavigationMenu('main') }} to="/">Deviant Robot</Link>

						</div>

						<nav className="primary" role="navigation" aria-labelledby="primary-navigation">

							<nav className="primary" role="navigation" aria-labelledby="primary-navigation">

								<div id="primary-navigation" className="visuallyhidden">Primary navigation</div>

								<div className="scroll-container">

									<div className="flex-container">

										<nav className={activePrimaryNavigationMenu === 'main' ? 'top active-menu' : 'top-menu'}>

											<Link onClick={toggleMenu} partiallyActive={true} activeClassName="current" to="/about">About</Link>
											<Link onClick={toggleMenu} partiallyActive={true} activeClassName="current" to="/blog">Blog</Link>
											<Link onClick={toggleMenu} partiallyActive={true} activeClassName="current" to="/opinion">Opinion</Link>
											<Link onClick={toggleMenu} partiallyActive={true} activeClassName="current" to="/interesting">Interesting</Link>
											<Link onClick={toggleMenu} partiallyActive={true} activeClassName="current" to="/recommended">Recomended</Link>

										</nav>

									</div>

								</div>

							</nav>

						</nav>

						{/* SECTION: Options navigation */}

						<div className="preference-toggle">

							<div className="options">

								<OptionsIcon onClick={toggleOptions} />

							</div>

						</div>

						<div className="preference-menu">

							{/* <div onClick={toggleOptions} className="closeOptions">Close</div> */}

							<div className="theme toggle">

								<div onClick={toggleDarkTheme} className={state.theme.dark ? 'active option' : 'option'}>

									<MoonIcon />

									<span>Switch to Light theme</span>

								</div>

								<div onClick={toggleDarkTheme} className={!state.theme.dark ? 'active option' : 'option'}>

									<SunIcon />

									<span>Switch to Dark theme</span>

								</div>

							</div>

						</div>

						{/* !SECTION: Options navigation */}

					</header>

				</Headroom>

				<div className="cookies-consent">

					<p>

						Can we use Cookies and Local Storage?
						<sub>We would like to use cookies and Local Storage to improve your user experainces; however by defualt we will not do so without your consent.</sub>

					</p>

					<p className="action">

						<Link className="button minor" to="/cookie-policy">Find out more</Link>
						<span className="button primary" onClick={cookieconsentToogle}>Yes, I agree</span>

					</p>

				</div>

				<div onClick={toggleOptions} className="scroll-lock-shade" />

				{/* SECTION: Main */}

				<main id="content" role="main" aria-labelledby="main-title" className="site">

					{children}

				</main>

				{/* !SECTION */}

				<footer className="site">

					<div className="page">

						<nav role="navigation" aria-labelledby="secondary-navigation">

							<div id="secondary-navigation" className="visuallyhidden">Secondary navigation</div>

							<a className="back-top-top" href="#top">Back to top</a>
							<Link activeClassName="current" to="/">Home</Link>
							<Link activeClassName="current" to="/getdeviant">Get Deviant</Link>
							<Link activeClassName="current" to="/cookie-policy">Cookie Policy</Link>

						</nav>

						<div className="pride">

							<nav role="navigation" aria-labelledby="social-navigation">

								<div id="social-navigation" className="visuallyhidden">Social navigation</div>

								<ReactGA.OutboundLink eventLabel="Facebook" to="https://www.facebook.com/DeviantRobot/"><FacebookIcon /></ReactGA.OutboundLink>
								<ReactGA.OutboundLink eventLabel="YouTube" to="https://www.youtube.com/c/deviantrobot"><InstagramIcon /></ReactGA.OutboundLink>
								<ReactGA.OutboundLink eventLabel="twitter" to="https://twitter.com/thedeviantrobot"><TwitterIcon /></ReactGA.OutboundLink>
								<ReactGA.OutboundLink eventLabel="Meetup" to="https://www.meetup.com/deviant-robot/"><MeetupIcon /></ReactGA.OutboundLink>
								<ReactGA.OutboundLink eventLabel="Instagram" to="https://www.instagram.com/deviantrobot"><YoutubeIcon /></ReactGA.OutboundLink>
								<ReactGA.OutboundLink eventLabel="Twitch" to="https://www.twitch.tv/deviantrobot"><TwitchIcon /></ReactGA.OutboundLink>

							</nav>

							<p>DeviantRobot.com was created and is owned by William Owen (<ReactGA.OutboundLink eventLabel="william-owen-uk" to="http://www.william-owen.co.uk">www.william-owen.co.uk</ReactGA.OutboundLink>). It has been made possible by a hugely supportive community for like-minded nerds.</p>
							<p>Special thank you to <ReactGA.OutboundLink eventLabel="ErmanKutluDesign" to="http://ermankutlu.co.uk/">Erman Kutlu (http://ermankutlu.co.uk/)</ReactGA.OutboundLink> for help with the Deviant Robot logo.</p>
							<p>CAUTION: DeviantRobot.com is safe to use while pregnant. Please do use this website while under the influence of alcohol. Avoid using while using any other site. Stop using if irritation develops. May cause drowsiness, DeviantRobot.com was not tested on animals, only family and friends. No HTML was harmed during the creation of this website. The site is 100% fat-free and suitable for Vegans. For external use only. Do not enter the Konami code while using this website</p>

						</div>

					</div>

					<Konami><div className="message">{KonamiMessage}</div></Konami>

					<DeviantRobotLogo />

					<p><strong>Deviant Robot. Relentlessly baddass since 2014</strong></p>
					<p className="last-build">Last build: {site.buildTime}</p>
					<p className="copyright">&copy; {new Date().getFullYear()} William Owen (William Owen UK Limited) unless otherwise stated. All rights reserved.</p>

				</footer>

			</div>

		</>

	)

}

Layout.propTypes = {

	children: PropTypes.node.isRequired,

}

const LayoutWithState = (props) => (

	<StateProvider initialState={initialState} reducer={reducer}>
		<Layout>
			{props.children}
		</Layout>
	</StateProvider>

)

LayoutWithState.propTypes = {

	children: PropTypes.node.isRequired,

}

export default LayoutWithState
