import React from "react"
import SEO from "../components/seo/seo"
import "./index.sass"
import PageTransition from "../components/PageTransition";

const CreditsPage = (props) => {

	return (

		<PageTransition {...props}>

			<SEO title="Special Thanks" />

			<div className="contentPage page">

				<h1 id="main-title">Special Thanks</h1>
				<p>Deviant Robot is made possible thanks to a lot of lovely people, projects and organisation, we want to say thank you to everyone who has helped us over the years and make it clear that this is far from a definative list of those who deserve our gratitude.</p>

				<section>

					<h2>Former Team members</h2>

					<ul>

						<li>

							<strong>Charlie Cox</strong>

						</li>
						<li>

							<strong>Erman Kutlu</strong> - <a href="http://ermankutlu.co.uk/project/deviant-robot">ermankutlu.co.uk</a><br />

						</li>
						<li>

							<strong>Fintan Keevans</strong>

						</li>
						<li>

							<strong>Hannah Capocci-Hunt</strong>

						</li>
						<li>

							<strong>Ian Thompson</strong>

						</li>
						<li>

							<strong>Kiron Capocci-Hunt</strong>

						</li>
						<li>

							<strong>Robin Young</strong>

						</li>
						<li>

							<strong>Thomas Hirsh</strong>

						</li>
						<li>

							<strong>Ziwie Deng</strong>

						</li>
					</ul>

					<h3></h3>
					<p></p>
					<p></p>

				</section>

				<section>

					<h2>Things we used to make the website</h2>

					<ul>

						<li>React - <a href="https://reactjs.org/">https://reactjs.org/</a></li>
						<li>Gatsby - <a href="https://www.gatsbyjs.org/">https://www.gatsbyjs.org/</a></li>
						<li>GraphQL - <a href="https://graphql.org/">https://graphql.org/</a></li>
						<li>GitHub - <a href="https://github.com/">https://github.com/</a></li>
						<li>Netlify - <a href="https://www.netlify.com/">https://www.netlify.com/</a></li>
						<li>Visual Studio Code - <a href="https://code.visualstudio.com/">https://code.visualstudio.com/</a></li>

					</ul>

				</section>

			</div>

		</PageTransition>

	)

}
export default CreditsPage
