import React from "react";
import posed, { PoseGroup } from "react-pose";

const PageTransition = (props) => {

	const { children, location } = props

	const PageContainer = posed.div({

		preEnterPose: { opacity: 0, x: -30 },
		enter: {
			opacity: 1,
			x: 0,
		},
		exit: {
			opacity: 0,
			x: -30,
		}

	})

	return (

		<PoseGroup animateOnMount className="PageContainer">

			<PageContainer withParent={false} key={location.pathname}>{children}</PageContainer>

		</PoseGroup>

	)

}

export default PageTransition;
