import React from 'react'
import posed, { PoseGroup } from 'react-pose'
import ArticleListingItem from '../ArticleListingItem';

const staggerDuration = 75;

const AnimationWrapper = posed.div({

	preEnterPose: { opacity: 0, y: -50 },

	enter: {
		opacity: 1,
		y: 0,
		delay: ({ i }) => i * staggerDuration
	},

	exit: { opacity: 0, y: -50 },

})

const ArticleListing = (props) => {

	const { skipPostID } = props
	const posts = props.posts

	return (

		<div className="article-listing">

			<PoseGroup animateOnMount>

				{posts.filter(post => post.node.frontmatter.title.length > 0).map(({ node: post }, i) => {

					if (post.id !== skipPostID) {

						const { frontmatter, fields, timeToRead } = post

						return (

							<AnimationWrapper withParent={false} i={i} key={fields.slug}>

								<ArticleListingItem {...fields} {...frontmatter} timeToRead={timeToRead} />

							</AnimationWrapper>

						)

					}

				})}

			</ PoseGroup>

		</div>)

}

export default ArticleListing
