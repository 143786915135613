// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-templates-article-js": () => import("/opt/build/repo/src/templates/article.js" /* webpackChunkName: "component---src-templates-article-js" */),
  "component---src-pages-404-js": () => import("/opt/build/repo/src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-js": () => import("/opt/build/repo/src/pages/about.js" /* webpackChunkName: "component---src-pages-about-js" */),
  "component---src-pages-blog-js": () => import("/opt/build/repo/src/pages/blog.js" /* webpackChunkName: "component---src-pages-blog-js" */),
  "component---src-pages-cookie-policy-js": () => import("/opt/build/repo/src/pages/cookie-policy.js" /* webpackChunkName: "component---src-pages-cookie-policy-js" */),
  "component---src-pages-getdeviant-js": () => import("/opt/build/repo/src/pages/getdeviant.js" /* webpackChunkName: "component---src-pages-getdeviant-js" */),
  "component---src-pages-index-js": () => import("/opt/build/repo/src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-interesting-js": () => import("/opt/build/repo/src/pages/interesting.js" /* webpackChunkName: "component---src-pages-interesting-js" */),
  "component---src-pages-opinion-js": () => import("/opt/build/repo/src/pages/opinion.js" /* webpackChunkName: "component---src-pages-opinion-js" */),
  "component---src-pages-recommended-js": () => import("/opt/build/repo/src/pages/recommended.js" /* webpackChunkName: "component---src-pages-recommended-js" */),
  "component---src-pages-special-thanks-js": () => import("/opt/build/repo/src/pages/special-thanks.js" /* webpackChunkName: "component---src-pages-special-thanks-js" */)
}

exports.data = () => import(/* webpackChunkName: "pages-manifest" */ "/opt/build/repo/.cache/data.json")

