import React, { useEffect } from "react"
import rehypeReact from "rehype-react"
import { graphql, Link } from "gatsby"
import PropTypes from "prop-types"
import Img from "gatsby-image"
import SEO from "../components/seo/seo"
import PageTransition from "../components/PageTransition"
import { useStateValue } from '../components/State'
import "./article.sass"
import BlockYoutube from "../components/BlockYoutube";
import BlockText from "../components/BlockText";
import BlockImage from "../components/BlockImage";
import BlockQuote from "../components/BlockQuote";
import ArticleListingItem from "../components/ArticleListingItem";

const renderAst = new rehypeReact({
	createElement: React.createElement,
	components: {
		"block-youtube": BlockYoutube,
		"block-text": BlockText,
		"block-image": BlockImage,
		"block-quote": BlockQuote
	}
}).Compiler

const ArticleWrapper = (props) => {

	return <PageTransition {...props}> <Article {...props} /> </PageTransition>

}

const Article = (props) => {

	const { data } = props
	const { markdownRemark: post } = data
	const [state, dispatch] = useStateValue()

	const {
		frontmatter,
		timeToRead,
		parent,
		fields
	} = post

	const {
		category,
		collection,
		slug,
	} = fields

	const {
		title,
		shortTitle,
		keywords,
		contentWarning,
		contentNote,
		author,
		videoCredit,
		introduction,
		featuredImageCredit,
		featuredImage,
		publicationDate,
		abstract,
		draft,
	} = frontmatter

	const { previousPost, nextPost } = props.pageContext

	const headImage = featuredImage
	const seoTitle = shortTitle ? shortTitle : title
	const description = abstract ? abstract : introduction
	const seoImage = headImage ? headImage.childImageSharp.seo.src : null

	useEffect(

		() => {

			if (state.page.title !== title) {

				dispatch({ type: 'SET_PAGE_TITLE', title })

			}

			return () => {

				if (state.page.title) {

					dispatch({ type: 'RESET_PAGE_TITLE' })

				}

			}
		}

	)

	let className = ""
	className = featuredImage ? "hasFeaturedIamge " + className : className
	className = draft ? "isDraft " + className : className

	return (

		< >

			<SEO title={seoTitle} keywords={keywords} description={description} image={seoImage} pathname={slug} />

			{headImage && <Img critical={true} fadeIn={false} alt={title} title={title} placeholderClassName="image-placeholder" className="featured-image" sizes={headImage.childImageSharp.main} />}

			<article className={className}>

				<header className="page">

					{draft && <div className="draft-warning">

						<h3>Draft Warning:</h3>
						This content is a draft and not intended for public view. If you have been sent this draft please do not forward or post it to social media.

					</div>}

					{contentWarning && <div className="content-warning">

						<h3>Contnet Warning:</h3>
						{contentWarning}

					</div>}

					<div className="category-collection">

						<div className="category">{category}</div>

						{
							(collection && (collection !== category))
							&& <div className="collection">{collection}</div>
						}

					</div>

					<h1>

						{title}

					</h1>

					<div className="article-meta">

						<div className="author">Written by:&nbsp;

							<strong>{author.name}&nbsp;</strong>

						</div>

						<div className="time-to-read">Reading time:&nbsp;

							<strong>{timeToRead} min&nbsp;</strong>

						</div>

					</div>

				</header>

				<div className="introduction">

					<p>{introduction}</p>

				</div>

				<div className="page">{renderAst(post.htmlAst)}</div>

				<footer>

					<div className="article-meta">

						{contentNote && <div className="content-note">{contentNote}</div>}

						<div className={(author && author.avatar) ? "author-bio" : "author-bio no-avatar"} >

							{author && author.avatar && <Img placeholderClassName="image-placeholder" className="author-avatar" sizes={author.avatar.childImageSharp.sizes} />}

							<div className="author-meta">

								<h2>Written by {author.name}</h2>

								<p className="bio">{author.bio}</p>

							</div>

						</div>

						<div className="publication-date">Published:&nbsp;

							<strong>{publicationDate}&nbsp;</strong>

						</div>

						<div className="last-modified">Last modified:&nbsp;

							<strong>{parent.mtime}&nbsp;</strong>

						</div>

						{videoCredit

							&& (

								<div className="image-credit">Video by:&nbsp;

									<strong>{videoCredit}&nbsp;</strong>

								</div>

							)

						}

						{featuredImageCredit

							&& (

								<div className="image-credit">Lead image by:&nbsp;

									<strong>{featuredImageCredit}&nbsp;</strong>

								</div>

							)

						}

					</div>

					<div className="next-and-previous">

						{previousPost && (

							<div className="previousNext">

								<h2>Previous article</h2>
								<ArticleListingItem {...previousPost.fields} {...previousPost.frontmatter} timeToRead={previousPost.timeToRead} />

							</div>

						)}

						{nextPost && (

							<div className="previousNext">

								<h2>Next article</h2>
								<ArticleListingItem {...nextPost.fields} {...nextPost.frontmatter} timeToRead={nextPost.timeToRead} />

							</div>

						)}

					</div>

				</footer>

			</article >

		</ >

	)

}

Article.propTypes = {

	data: PropTypes.object.isRequired,

}

export default ArticleWrapper

export const pageQuery = graphql`

	query ArticleBySlug($slug: String!) {

		markdownRemark(

			fields: {

				slug: {
					eq: $slug
				},

			}){
				id
				htmlAst
				wordCount {
					words
				}
				timeToRead
				fields {
					slug
					category
					collection
				}
				parent {
					...on File {
						mtime(formatString: "MMMM Do YYYY")
					}
				}
				frontmatter {
					title
					publicationDate(formatString: "MMMM Do YYYY")
					shortTitle
					abstract
					contentWarning
					contentNote
					keywords
					draft
					author {
					...AuthorQuery
					}
					videoCredit
					introduction
					featuredImageCredit
					featuredImage {
						childImageSharp {
							main:sizes(maxWidth: 2000) {
								...GatsbyImageSharpSizes
							}
							seo:sizes(maxWidth: 1200) {
								src
							}
						}
					}

				}

			}

		}

`
