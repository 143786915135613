import React from "react"
import { StaticQuery, graphql } from "gatsby"
import Img from "gatsby-image"

const Image = () => (
	<StaticQuery
		query={graphql`
      query {
        placeholderImage: file(relativePath: { eq: "soup_hero.png" }) {
          childImageSharp {
            fluid(maxWidth: 800) {
              ...GatsbyImageSharpFluid
            }
          }
        }
      }
    `}
		render={data => <Img critical={true} fluid={data.placeholderImage.childImageSharp.fluid} />}
	/>
)

export default Image