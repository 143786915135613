import React from "react"
import SEO from "../components/seo/seo"
import "./contentpage.sass"
import PageTransition from "../components/PageTransition";
import { Link } from "gatsby";
import ReactGA from 'react-ga';

const EventsPage = (props) => {

	return (

		<PageTransition {...props}>

			<SEO
				title="Get Deviant"
				description='Find out how to support the Deviant Robot project.'
				pathname="/getdeviant"
			/>

			<div className="page contentPage">

				<h1 id="main-title">Get Deviant</h1>

				<section>

					<h2>More coming soon</h2>

					<p>We are working on lots of ways for you to get more involved with help, support, follow and generally be all about Deviant Robot. But for now, the options are a little limited. Regardless do not let that stop you going nuts with the following links: -</p>

					<ul>
						<li><ReactGA.OutboundLink eventLabel="twitter" to="https://twitter.com/thedeviantrobot">Twitter</ReactGA.OutboundLink></li>
						<li><ReactGA.OutboundLink eventLabel="Facebook" to="https://www.facebook.com/DeviantRobot/">Facebook</ReactGA.OutboundLink></li>
						<li><ReactGA.OutboundLink eventLabel="Twitch" to="https://www.twitch.tv/deviantrobot">Twitch</ReactGA.OutboundLink></li>
						<li><ReactGA.OutboundLink eventLabel="Instagram" to="https://www.instagram.com/deviantrobot">Instagram</ReactGA.OutboundLink></li>
						<li><ReactGA.OutboundLink eventLabel="YouTube" to="https://www.youtube.com/c/deviantrobot">YouTube</ReactGA.OutboundLink></li>
						<li><ReactGA.OutboundLink eventLabel="Meetup" to="https://www.meetup.com/deviant-robot/">Meetup</ReactGA.OutboundLink></li>
					</ul>

				</section>

			</div>

		</PageTransition>

	)

}

export default EventsPage
