import React, { useState, useEffect } from "react"
import { useStateValue } from '../components/State'
import SEO from "../components/seo/seo"
import "./index.sass"
import PageTransition from "../components/PageTransition";

const CookiePageWrapper = (props) => {

	return <PageTransition {...props}> <CookiePagePage {...props} /> </PageTransition>

}

const CookiePagePage = (props) => {

	const [currentLocalStorage, setCurrentLocalStorage] = useState(null)
	const [state, dispatch] = useStateValue();

	const revolkConcent = () => {

		if (typeof window !== `undefined`) {

			dispatch({ type: 'DISABLE_COOKIE_consent' })

		}

	}

	useEffect(() => {

		if (typeof window !== `undefined`) {

			setCurrentLocalStorage(JSON.parse(window.localStorage.getItem("applicatinState")))

		}

	})

	return (

		< >

			<SEO
				title="Cookie and Privacy Policy"
				description='The Deviant Robot cookie and privacy policy.'
				pathname="/cookie-policy"
			/>

			<div className="homepage page">

				<h1 className="minor" id="main-title">Cookie and Local Storage Policy for Deviant Robot</h1>

				<p><em>Effective 25th April 2019</em></p>

				<section>

					<h2>What Are Cookies?</h2>

					<p>As is common practice with almost all websites DeviantRobot.com uses cookies. Cookies are tiny text files that can be downloaded to your computer so that websites and web services can remember information about you.</p>
					<p>This page describes the cookies that deviantrobot.com would like to use, what information they gather, and how we use that information.</p>
					<p>For more general information on cookies see the <a href="https://en.wikipedia.org/wiki/HTTP_cookie">Wikipedia article on HTTP Cookies</a></p>

				</section>

				<section>

					<h2>What is local storage?</h2>

					<p>Similar to a Cookie, Local Storage is a way of storing small amounts of data in your browser so that deviantrobot.com can remember information about you.</p>
					<p>DeviantRobot.com usees Local Storage to maintain your user preferences across sessions. For example the 'light' or 'dark' theme preference, and even the acceptance of our cookie policy. This means that when you come back to deviantrobot.com from the same browser at a later time your choices will still apply.</p>

					{(typeof window !== `undefined`) && currentLocalStorage && state.cookies.consent &&

						<>

							<h3>What are we storing in Local Storage?</h3>

							<p>Currently this is the informatino we are storing in local storage is as follows (Note: this information is updated in real-time): -</p>

							<pre><code>

								{JSON.stringify(currentLocalStorage, null, '\t')}

							</code></pre>

						</>

					}

				</section>

				<section>

					<h2>Google Analytics (with IP Anonymization)</h2>

					<p>This site uses Google Analytics which is one of the most widespread and trusted analytics solution on the web for helping us to understand how you use the site. We use this information to improve your experience and undertand what contnet people are engaging with. This infomration provides positive feedback to our contnet authors by allowing them to know that an audience is engaging with there contnet.</p>

					<p>These cookies may track things such as how long you spend on the site and the pages that you visit so we can continue to produce engaging content.</p>

					<p>We enable the <strong>IP Anonymization in Analytics</strong> in our analytics tracking to protect your identity. Find out more about <a href="https://support.google.com/analytics/answer/2763052?hl=en">IP Anonymization in Google Analytics</a></p>

					<p>For more information on Google Analytics cookies, <a href="https://developers.google.com/analytics/devguides/collection/analyticsjs/cookie-usage?hl=en">see the official Google Analytics page</a>.</p>

					<h3>Google EU Privacy Shield</h3>

					<p>The U.S. Department of Commerce has approved Google&#39;s certification to the Privacy Shield as fully compliant. <a href="https://www.privacyshield.gov/participant?id=a2zt000000001L5AAI">View Google’s Privacy Shield certification</a>.</p>

					<h3>Google ISO 27001</h3>

					<p><a href="https://storage.googleapis.com/support-kms-prod/bk5ogXBjnUep68clvPvX8lSayrFt5ZZAMC5G">Google has earned ISO 27001 certification</a> for the systems, applications, people, technology, processes, and data centres serving a number of Google products, including Google Analytics. <a href="https://www.iso.org/isoiec-27001-information-security.html">Learn more about ISO 27001</a>.</p>

				</section>

				<section>

					<h2>YouTube Embedded videos (privacy-enhanced)</h2>

					<p>Deviant Robot may contain embedded YouTube videos within its content. We use the privacy-enhanced mode for our embedded YouTube videos that will prevent YouTube storing information unless you play the video.</p>

					<p>If you choose to play embedded video content from YouTube you are subject to the <a href="https://www.youtube.com/yt/about/policies/">privacy policy and terms of use</a>.</p>

				</section>

				<section>

					<h2>Cookies details</h2>

					<p>These cookies collect information that is used either in aggregate form to help us understand how our websites are being used or how effective our marketing campaigns are, or to help us customize our websites and application for you in order to enhance your experience.</p>

					<table>

						<thead>

							<tr>

								<th>Categories</th>
								<th>Cookies</th>

							</tr>

						</thead>

						<tbody>

							<tr>

								<td>

									<strong>Google Analytics</strong>
									<br /><br />
									Google Analytics gathers information allowing us to understand interactions with our websites and ultimately refine that experience to better serve you. This infomration provides positive feedback to our contnet authors by allowing them to know that an audience is engaging with there contnet.

								</td>

								<td>

									_gat
									<br />
									_ga

								</td>

							</tr>

							<tr>
								<td>
									<strong>YouTube (Set by Google's YouTube.com)</strong>
									<br /><br />
									Provides access to the embeded YouTube content, if you are on a page with an embeded YouTube video, and <strong>only if you elect to play that video.</strong>
								</td>
								<td>
									SID<br />
									HSID<br />
									demographics<br />
									VISITOR_INFO1_LIVE<br />
									PREF<br />
									APISID<br />
									SSID<br />
									LOGIN_INFO<br />
									YSC<br />
									SAPISID
								</td>

							</tr>

						</tbody>

					</table>

				</section>

				{state.cookies.consent && <section>

					<h2>Revoke Consent</h2>

					<p><strong className="warning">Warning</strong> If you wish to revoke cookie consent and delete local storage please click on the button below. However if you do so deviantrobot.com will no longer maintain your preferences between sessions and your user experaince may be degreaded. Cookies that have already beeen set will need to be cleared manually.</p>

					<button className="danger" onClick={revolkConcent}>Revolk Local Storage and Cookie consent</button>

				</section>}

				<section>

					<h2>To clear exisiting cookies</h2>

					<p>To clear your cookies you will need to refer to your indevidual browsers support documentation.</p>

					<p> The following links may provided some guidence hoever deviantrobot.com is not responsible for the content matinaed externally. <a href="https://support.google.com/accounts/answer/32050?co=GENIE.Platform%3DDesktop&hl=en">Clear cookies in Google Chrome</a> or <a href="https://support.mozilla.org/ta/kb/clear-cookies-and-site-data-firefox?redirectlocale=en-US&redirectslug=delete-cookies-remove-info-websites-stored">Clear cookies in FireFox</a></p>

				</section>

				<section>

					<h2>More Information</h2>

					<p>Hopefully that has clarified things for you and as was previously mentioned if there is something that you aren&#39;t sure whether you need or not it&#39;s usually safer to leave cookies enabled in case it does interact with one of the features you use on our site. However if you are still looking for more information then you can contact us through one of our preferred contact methods.</p>

					<p>Email: privacypolicy@deviantrobot.com</p>

				</section>

			</div >

		</ >

	)

}

export default CookiePageWrapper
