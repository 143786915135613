import React from "react"
import { graphql } from "gatsby"
import PropTypes from "prop-types"
import SEO from "../components/seo/seo"
import ArticleListing from "../components/ArticleListing"
import "./index.sass"
import PageTransition from "../components/PageTransition";
import ArticleListingItem from "../components/ArticleListingItem";

const IndexPage = (props) => {

	const { data } = props
	const posts = data.posts.edges

	const { node: firstPost } = data.featuredPost.edges[0]

	return (

		<PageTransition {...props}>

			<SEO
				title="Deviant Robot: Meandering adventures into interesting ideas."
				titleTemplate={`%s`}
				description="The world is full of interesting ideas and Deviant Robot will serve them up to you with a light side salad of sass and charm."
				pathname="/"
			/>

			<div className="homepage">

				<h1 className="visuallyhidden">Deviant Robot: Meandering adventures into interesting ideas.</h1>

				<div className="featured-post">

					<ArticleListingItem featured {...firstPost.fields} {...firstPost.frontmatter} timeToRead={firstPost.timeToRead} />

				</div>

				<ArticleListing skipPostID={firstPost.id} posts={posts} />

			</div>

		</PageTransition>

	)

}

IndexPage.propTypes = {

	data: PropTypes.object.isRequired,

}

export default IndexPage

export const query = graphql`

	fragment indexListing on MarkdownRemarkEdge {

		node {
			id
			timeToRead
			...FieldsQuery
			frontmatter {
				abstract
				title
				hasVideo
				isNsfw
				tileImage {
					childImageSharp {
						sizes(maxWidth: 630) {
							...GatsbyImageSharpSizes
						}
					}
				}
				featuredImage {
					childImageSharp {
						sizes(maxWidth: 630) {
							...GatsbyImageSharpSizes
						}
					}
				}
				publicationDate(formatString: "MMMM DD, YYYY")
			}
		}

	}

`

export const AuthorQuery = graphql`

	fragment AuthorQuery on AuthorYaml {

		id
		name
		bio
		avatar {
			childImageSharp {
				sizes(maxWidth: 360) {
					...GatsbyImageSharpSizes
				}
			}
		}

	}

`

export const FieldsQuery = graphql`

	fragment FieldsQuery on MarkdownRemark {

		fields {
			slug
			category
			collection
		}

	}

`

export const pageQuery = graphql`

	query {

		featuredPost: allMarkdownRemark(

			limit: 1

			filter: {
				fields: {
					category: {ne: "Guides"}
				}
				frontmatter: {
					draft: {ne: true}
					noFeature: {ne: true}
				}
			}

			sort: {
				order: DESC, fields: [frontmatter___publicationDate]
			}

		) {
			edges {
				...indexListing
			}
		}

		posts: allMarkdownRemark(

			limit: 11

			filter: {
				fields: {
					category: {ne: "Guides"}
				}
				frontmatter: {
					draft: {ne: true}
				}
			}

			sort: {
				order: DESC, fields: [frontmatter___publicationDate]
			}

		) {
			edges {
				...indexListing
			}
		}
	}

`