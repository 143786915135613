import React from "react"
import PropTypes from "prop-types"
import "./BlockQuote.sass"

const BlockQuote = (props) => {

	const { children, cite, source } = props

	return (

		<blockquote cite={source} className="block-quote">

			<main>

				{children}

			</main>
			<footer>

				— {cite}

			</footer>

		</blockquote>
	)

}

BlockQuote.propTypes = {

	children: PropTypes.node,
	cite: PropTypes.string,
	source: PropTypes.string,

}

export default BlockQuote