import React from "react"
import { graphql } from "gatsby"
import PropTypes from "prop-types"
import SEO from "../components/seo/seo"
import ArticleListing from "../components/ArticleListing/"
import "./index.sass"
import PageTransition from "../components/PageTransition";

const BlogPage = (props) => {

	const { data } = props
	const posts = data.allMarkdownRemark.edges

	return (

		<PageTransition {...props}>

			<SEO
				title="Blog"
				description='Find out about whats going on with the Deviant Robot project.'
				pathname="/blog"
			/>

			<div className="homepage">

				<h1 id="main-title">Blog</h1>
				<p className="lead-message">Find out about whats going on with the project.</p>

				<ArticleListing posts={posts} />

			</div>

		</PageTransition>

	)

}

BlogPage.propTypes = {

	data: PropTypes.object.isRequired,

}

export default BlogPage

export const pageQuery = graphql`

	query {

		allMarkdownRemark(

			filter: {
				fields: {
					category: {eq: "Blog"}
				}
				frontmatter: {
					draft: {ne: true}
				}
			}

			sort: {
				order: DESC, fields: [frontmatter___publicationDate]
			}

		) {
		edges {
				...indexListing
			}
		}
	}

`