import React from "react"
import PropTypes from "prop-types"
import "./BlockYoutube.sass"

const BlockYoutube = (props) => {

	const { video, children } = props

	const myregexp = /(?:youtube\.com\/(?:[^\/]+\/.+\/|(?:v|e(?:mbed)?)\/|.*[?&]v=)|youtu\.be\/)([^"&?\/ ]{11})/i;
	const videoID = video.match(myregexp)[1]

	return (

		<div className="block-youtube">

			<div class='embed-container'>

				<iframe title="YouTube video" src={`https://www.youtube-nocookie.com/embed/${videoID}`} allow="accelerometer; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>

			</div>

			{children &&
				<div class="description">
					{children}
				</div>
			}

		</div>
	)

}

BlockYoutube.propTypes = {

	video: PropTypes.string.isRequired,
	children: PropTypes.nodes,

}

export default BlockYoutube